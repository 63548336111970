import React from "react"
import Button from "@mui/material/Button"

const VCAccessInformation = ({ actionItems }) => {
    return (
        <div id="accessInformation">
            {actionItems && !!actionItems.length && actionItems.map(
                ({ name, action, color }) => (
                    <div key={name} className="actionButtonWrapper">
                        <Button 
                            variant="contained"
                            sx={{
                                backgroundColor: color,
                                borderRadius: "2px",
                                fontWeight: "bold",
                                padding: "12px",
                                "&:hover": {
                                    opacity: 0.9,
                                    backgroundColor: color
                                },
                                boxShadow: 24
                            }}
                            elevation={1}
                            onClick={action}
                            className="actionButton"
                        >
                            {name}
                        </Button>
                    </div>
                )
            )
            }
        </div>
    )
}

export default VCAccessInformation