import React, {
    useEffect,
    useState,
} from "react"

import {
    useUppy
} from "@uppy/react"

import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import Typography from "@mui/material/Typography"
import Uppy from "@uppy/core"

// Custom components
import VCNavigationSubheader from "./Component/VCNavigationSubheader"
import WordModal from "./Component/WordsDialog"
import NewItemTile from "./Component/NewItemTile"
import ActionItemTile from "./Component/ActionItemTile"
import VCLoadingView from "./VCLoadingView"
import StudentsModal from "./Component/StudentsDialog"
import Dictaphone from "./Component/Dictaphone"

// View Model
import {
    getWordbankID,
    uppyConfig,
    deleteWord as deleteWordData
} from "../ViewModel/WordsVM"

import "@uppy/core/dist/style.css"
import "@uppy/dashboard/dist/style.css"
import "./Stylesheet/Wordbank.css"

let uppy = undefined
let wordMap = {}

const Words = (props) => {

    const {
        firestore,
    } = props
    const [words, setWords] = useState([])
    const [showWordModal, setShowWordModal] = useState(false)
    const [showStudentsModal, setShowStudentsModal] = useState(false)
    const [editingWordbank, setEditingWord] = useState(undefined)
    const [loading, setLoading] = useState(false)
    const [wordbankName, setWordbankName] = useState(undefined)
    const [classCode, setClassCode] = useState(undefined)
    const [wordbank, setWordbank] = useState(undefined)
    const [wordbankID, setWordBankID] = useState(getWordbankID())
    const [inSession, setInSession] = useState(false)
    const [currentlySpokenWord, setCurrentlySpokenWord] = useState(undefined)

    const loadWords = async () => {
        let tempWordNames = []
        setLoading(true)
        setShowWordModal(false)
        const wbData = await firestore?.read(
            "Wordbank",
            wordbankID,
        )
        setWordbank({ ...wbData, id: wordbankID })
        const wordIDs = wbData.wordIDs
        setWordbankName(wbData.name)
        setClassCode(wbData.classCode)
        let w = []
        for (const i in wordIDs) {
            const wid = wordIDs[i]
            const wordData = await firestore?.read(
                "Word",
                wid,
            )
            tempWordNames.push(wordData.name)
            w.push({ ...wordData, id: wid })
            wordMap[wid] = wordData
        }
        setWords(w)
        setLoading(false)
    }

    const displayWordCreationUI = () => {
        setShowWordModal(true)

    }

    const closeWordCreationUI = () => {
        setShowWordModal(false)
    }

    const displayStudentsUI = () => {
        setShowStudentsModal(true)

    }

    const closeStudentsUI = () => {
        setShowStudentsModal(false)
    }

    const editWord = (wb) => {
        setEditingWord(wb)
        displayWordCreationUI()
    }

    const deleteWord = async (w) => {
        await deleteWordData(firestore, wordbankID, w.id)
        loadWords()
    }

    const handleWordSpoken = async (spokenWord) => {
        firestore?.update(
            "Wordbank",
            wordbank.id,
            {
                wordBeingSpoken: {
                    ...spokenWord,
                    imageURL: spokenWord.imageURL ?? null,
                    fullImagePath: spokenWord.fullImagePath ?? null,
                }
            }
        )
    }

    const onSessionStart = () => {
        firestore?.update(
            "Wordbank",
            wordbank.id,
            {
                inSession: !inSession,
                wordBeingSpoken: null,
            }
        )
        setInSession(!inSession)
    }

    useEffect(() => {
        loadWords()
        firestore?.update(
            "Wordbank",
            getWordbankID(),
            {
                inSession: false
            }
        )
    }, [firestore])

    uppy = useUppy(() => {
        return new Uppy(uppyConfig)
    })
        
    return (
        <Box>
            <Dialog
                open={showWordModal}
                onClose={closeWordCreationUI}
            >
                <Box className="modal">
                    <WordModal 
                        firestore={firestore}
                        uppy={uppy}
                        onCreate={loadWords}
                        editingWord={editingWordbank}
                    />
                </Box>
            </Dialog>
            <Dialog
                open={showStudentsModal}
                onClose={closeStudentsUI}
            >
                <Box className="modal">
                    <StudentsModal 
                        firestore={firestore} 
                        wordbank={wordbank}
                        words={wordMap}
                        setWordbank={setWordbank}
                        wordMap={wordMap}
                    />
                </Box>
            </Dialog>
            {loading ? <VCLoadingView /> : (
                <>
                    <VCNavigationSubheader>
                        <Typography flex={8}>
                            Words in the {wordbankName} wordbank. Class code: {String(classCode).padStart(4, "0")}
                        </Typography>

                        <Button 
                            style={{
                                margin: "-5px 2px",
                                backgroundColor: "orange",
                            }}
                            flex={4}
                            variant="contained"
                            onClick={displayStudentsUI}
                        >
                            Students
                        </Button>
                    </VCNavigationSubheader>
                    <Box
                        sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "center",
                            padding: "20px",
                        }}
                    >
                        <NewItemTile 
                            createNewItem={displayWordCreationUI}
                            createItemHeading="Create New Word"
                        />
                        {words.map(
                            w => (
                                <ActionItemTile 
                                    deleteAction={deleteWord}
                                    editAction={editWord}
                                    actionItem={w}
                                    key={w.id} 
                                    cursor="unset"
                                />
                            )
                        )}
                    </Box>
                    <Dictaphone 
                        micIsOn={inSession} 
                        micClickHandler={onSessionStart} 
                        setCurrentlySpokenWord={handleWordSpoken}
                        words={words}
                    />
                </>
            )}
        </Box>
    )
}

export default Words