import React from "react"
import VCLink from "./VCLink"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"

const VCNavBarPopover = (props) => {
    const {
        show, 
        anchorEl,
        closeNavbarPopover
    } = props

    return (
        <Menu 
            open={show} anchorEl={anchorEl}
            onClose={closeNavbarPopover}
            onClick={closeNavbarPopover}
        >
            <MenuItem>
                <VCLink href="account">Account</VCLink>
            </MenuItem>
            <MenuItem>
                <VCLink href="signout">Sign Out</VCLink>
            </MenuItem>
        </Menu>
    )
}

export default VCNavBarPopover