import React, {
    createContext,
    useState,
    useEffect
} from "react"

import {
    initializeApp
} from "firebase/app"

import {
    EmailAuthProvider,
    getAuth,
    signOut,
    signInWithEmailAndPassword,
    sendPasswordResetEmail,
    updateEmail,
    updateProfile,
    updatePassword,
    reauthenticateWithCredential,
    createUserWithEmailAndPassword,
    GoogleAuthProvider,
    signInWithPopup,
} from "firebase/auth"

import {
    addDoc,
    getFirestore,
    collection,
    doc,
    getDoc,
    setDoc,
    deleteDoc,
    deleteField,
    updateDoc,
} from "firebase/firestore/lite"

import {
    getStorage,
    ref,
    uploadBytesResumable,
    getDownloadURL,
    deleteObject,
} from "firebase/storage"

import {
    useNavigate
} from "react-router-dom"

import {
    v4 as uuidv4
} from "uuid"

// Conditionally import the dev config file if env is set
const firebaseConfig = process.env.REACT_APP_DEV_ENV === 1 
    ? require("./DevFirebaseConfig").firebaseConfig
    : require("./FirebaseConfig").firebaseConfig

// Initialize firebase and the firestore
const app = initializeApp(firebaseConfig)
const firestore = getFirestore(app)
const auth = getAuth(app)
const storage = getStorage()
const googleProvider = new GoogleAuthProvider()

const {
    Provider,
    Consumer
} = createContext()

const FirebaseContextProvider = (props) => {

    const {
        children
    } = props

    const [user, setUser] = useState(undefined)

    const navigate = useNavigate()

    const handleUserAuthStateChange = (u) => {
        setUser(u)
        if (user !== u)
            navigate("/", {
                replace: true
            })
    }

    useEffect(
        () => {
            const listener = auth.onAuthStateChanged(handleUserAuthStateChange)
            return function cleanup() {
                listener && listener()
            }
        },
        [firestore]
    )

    const initializeDocument = async (collectionName, documentName) => {
        let document
        let docRef = doc(firestore, collectionName, documentName)
        await getDoc(docRef).then(
            docSnap => {
                if (docSnap.exists()) {
                    document = docSnap
                } else {
                    docRef = undefined
                }
            }
        ).catch(e => JSON.stringify(console.error))

        return {
            document,
            docRef
        }
    }

    /**
     * Create a firestore document with a given collection and doc name
     *
     * @param {string} collectionName The collection to create the doc in
     * @param {string} documentName The document name to be created
     * @param {object} documentData The data of the new doc
     */
    const create = async (collectionName, documentName, documentData) => {
        let newDocument
        if (documentName) {
            newDocument = doc(firestore, collectionName, documentName)
            await setDoc(newDocument, documentData, {
                merge: true
            })
        } else { // generate the document name
            newDocument = await addDoc(
                collection(firestore, collectionName),
                documentData
            )
        }

        return newDocument
    }

    /**
     * Read a firestore document with a given collection and doc name
     *
     * @param {string} collectionName The collection containing the doc
     * @param {string} documentName The name of the doc to be read
     * @returns {object} The data if set, else an empty object
     */
    const read = async (collectionName, documentName) => {
        const {
            document,
            docRef
        } = await initializeDocument(collectionName, documentName)

        return (document && document.data()) ?? {}
    }

    /**
     * Update a firestore document with a given collection and doc name
     *
     * @param {string} collectionName The collection containing the doc
     * @param {string} documentName The name of the doc to be updated
     * @param {object} documentData The data to update the doc with
     * @param {boolean} merge Merge the data to avoid overwrites (true by default)
     */
    const update = async (collectionName, documentName, documentData, merge=true) => {
        const {
            document,
            docRef
        } = await initializeDocument(collectionName, documentName)

        if (document) {
            await setDoc(docRef, documentData, {
                merge: merge
            })
        }
    }

    /**
     * Delete an account from Firebase
     *
     * @param {string} password The account password (confirmation)
     */
    const deleteAccount = async (password) => {
        let result
        try {
            const credential = EmailAuthProvider.credential(
                user.email,
                password
            )
            result = await reauthenticateWithCredential(
                auth.currentUser,
                credential
            )
        } catch (error) {
            alert("Invalid credentials. Please try again.")
            return
        }
        if (!result.user) {
            alert("Invalid credentials. Please try again.")
            return
        }
        const userData = await read("User", user.uid)
        const userWordbankIDs = userData.wordbankIDs
        for (const i of userWordbankIDs) {
            const wordbankID = userWordbankIDs[i]
            const wordBankData = await read("WordBank", wordbankID)
            for (const j of wordBankData.wordIDs) {
                const wordID = wordBankData.wordIDs[i]
                await deleteDocument("Word", wordID)
                // Delete image for word
                // Delete "TestedWords"
            }
            await deleteDocument("Wordbank", wordbankID)
            await deleteImage(wordBankData?.fullImagePath)
            await deleteDocument("Engagement", wordbankID)
            const studentIDs = wordBankData.studentIDs
            for (const k of studentIDs) {
                const studentID = studentIDs[k]
                await deleteDoc("Student", studentID)
            }
        }
        await auth.currentUser.delete()
        alert("Your account and data have been deleted successfully.")
    }

    /**
     * Delete a specific field from a document
     *
     * @param {string} collectionName The collection name containing the document
     * @param {string} fieldName The name of the field to be deleted
     * @param {string} documentName The document to be deleted from
     */
    const deleteDocumentField = async (collectionName, fieldName, documentName) => {
        const {
            document,
            docRef
        } = await initializeDocument(collectionName, documentName)

        if (document) {
            updateDoc(docRef, {
                [fieldName]: deleteField()
            })
        }
    }

    /**
     * Delete a firestore document with a given collection and doc name
     *
     * @param {string} collectionName The collection name containing the document
     * @param {string} documentName The document to be entirely deleted
     */
    const deleteDocument = async (collectionName, documentName) => {
        const {
            document,
            docRef
        } = await initializeDocument(collectionName, documentName)

        if (document)
            deleteDoc(docRef)
    }

    /**
     * Authenticate a user with a given email and password
     *
     * @param {string} email The email of the user
     * @param {string} password The password of the user
     * @returns {string} on login error an error string
     */
    const signInWithEmail = async (email, password) => {
        let signInError = undefined
        await signInWithEmailAndPassword(auth, email, password).catch(
            error => {
                signInError = "Your login credentials could not be verified."
            }
        )
        return signInError
    }

    /**
     * Authenticate using the google firebase API
     */
    const signInWithGoogle = async () => {
        signInWithPopup(auth, googleProvider)
            .then((result) => {
                const credential = GoogleAuthProvider.credentialFromResult(result)
                const token = credential.accessToken
                const user = result.user
            }).catch((error) => {
                const errorCode = error.code
                const errorMessage = error.message
                const email = error.customData.email
                const credential = GoogleAuthProvider.credentialFromError(error)
            })
    }

    /**
     * Sign up for an account with a given email and password
     *
     * @param {string} email The email of the user
     * @param {string} password The password of the user
     * @param {string} firstName The first name of the user
     * @param {string} lastName The last name of the user
     */
    const signUpWithEmail = async (email, password, firstName, lastName) => {
        let signUpError = undefined
        if (!firstName.length || !lastName.length) {
            signUpError = "Please enter a first and last name."
            return signUpError
        }
        await createUserWithEmailAndPassword(auth, email, password).catch(
            error => {
                let errorCode = error.code
                if (errorCode === "auth/weak-password") {
                    signUpError = "Your password does not meet the minimum requirements."
                } else {
                    signUpError = "There was an error creating your account with the given information."
                }
            }
        )
        if (!signUpError) {
            try {
                const currentUser = auth.currentUser
                await create("User", currentUser.uid, {
                    firstName: firstName,
                    lastName: lastName,
                    wordbankIDs: []
                })
                await updateProfile(currentUser, {
                    displayName: firstName
                })
            } catch (error) {
                signUpError = "There was an issue updating the user profile"
                console.error(error)
                alert(signUpError)   
            }
        }
        return signUpError
    }

    /**
     * Sign out the current firebase user (terminate session)
     */
    const signOutUser = async () => {
        await signOut(auth)
    }

    /**
     * Handle user password reset via email
     *
     * @param {string} email The email of the user
     * @returns {boolean} true if the reset was successful, else false
     */
    const handlePasswordReset = async (email) => {
        let success = true
        await sendPasswordResetEmail(auth, email).catch(
            err => success = false
        )
        return success
    }

    /**
     * Upload an image to the firebase storage bucket
     *
     * @param {object} imageFile the uppy file instance being uploaded
     * @param {Function} onUploadComplete callback to set the download url
     * @param {Function} setProgress callback to update the progress bar
     * @returns {*} download url, image path on success, false otherwise
     */
    const uploadImage = (imageFile, onUploadComplete, setProgress) => {
        const blob = imageFile.data.slice(0, -1)
        const imagePath = uuidv4()
        const fileData = new File([blob], imagePath, {
            type: imageFile.type
        })

        if (fileData) {
            const imageRef = ref(storage, imagePath)
            const uploadTask = uploadBytesResumable(imageRef, fileData)
            uploadTask.on("state-changed", 
                (snapshot) => {
                    setProgress(
                        snapshot.bytesTransferred * 100 / snapshot.totalBytes
                    )
                },
                (e) => {
                    console.error(e)
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
                        onUploadComplete({ downloadURL, imagePath })
                    })
                },
            )
        }

        return false
    }

    /**
     * Delete an image from the storage bucket
     *
     * @param {string} imageName The actual name of the image to be deleted
     * @returns {boolean} true if delete was successful, else false
     */

    const deleteImage = async (imagePath) => {
        // Create a reference to the file to delete
        if (!imagePath) {
            return false
        }
        const imageRef = ref(storage, imagePath)

        // Delete the file
        await deleteObject(imageRef)
        return true
    }

    /**
     * Handle user profile and information updates
     *
     * @param {string} email The email of the user
     * @param {string} oldPassword The old password of the user
     * @param {string} newPassword The new password of the user
     * @param {string} firstName The first name of the user
     * @param {string} lastName The last name of the user
     * @returns {boolean} true if the user info update was successful, else false
     */
    const updateUserAccountInfo = async (email, oldPassword, newPassword, firstName, lastName) => {
        if (!user) {
            return false
        }
        let userUpdateData = {}
        if (firstName) {
            await updateProfile(auth.currentUser, {
                displayName: firstName
            })
            userUpdateData.firstName = firstName
        }
        if (lastName)
            userUpdateData.lastName = lastName

        if (email || newPassword) {
            if (!oldPassword)
                return false
            const credential = EmailAuthProvider.credential(
                user.email,
                oldPassword
            )
            const result = await reauthenticateWithCredential(
                auth.currentUser,
                credential
            )
            if (!result.user) {
                alert("Invalid credentials. Please try again.")
                return
            }
            if (email) {
                try {
                    await updateEmail(
                        auth.currentUser,
                        email
                    )
                } catch (error) {
                    alert("The email you provided was not valid")
                    return false
                }
            }
            if (newPassword) {
                try {
                    await updatePassword(
                        auth.currentUser,
                        newPassword
                    )
                } catch (error) {
                    alert("Your new password could not be saved. Please try again.")
                    return false
                }
            }
        }
        if (Object.keys(userUpdateData).length > 0)
            await update("User", auth.currentUser.uid, userUpdateData)
        
        alert("Your account was updated successfully")
        return true
    }

    return (
        <Provider value={{
            firestore: {
                create,
                read,
                update,
                deleteAccount,
                deleteDocument,
                deleteDocumentField,
                signInWithEmail,
                signInWithGoogle,
                signUpWithEmail,
                signOutUser,
                uploadImage,
                deleteImage,
                updateUserAccountInfo,
                user
            } }
        }>
            {children}
        </Provider>
    )
}

export {
    FirebaseContextProvider
}
export default Consumer