import React from "react"
import ReactPlayer from "react-player"

const VCVideoReel = (props) => {
    const {
        videoURL,
        style,
        width,
        height,
    } = props
    return (
        <ReactPlayer 
            url={videoURL}
            width={width}
            height={height}
            style={{
                ...style
            }}
        />
    )
}

export default VCVideoReel