import React,{
    useEffect
} from "react"

const Signout = (props) => {
    
    const {
        firestore,
    } = props

    useEffect(()=>{
        firestore?.signOutUser()
    }, [firestore])
    
    return (
        <p>Unable to sign out the current user. Please contact the administrator to report this issue.</p>
    )
}

export default Signout