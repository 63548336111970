import React from "react"
import Box from "@mui/material/Box"

const VCNavigationSubheader = (props) => {
    const {
        children
    } = props

    return (
        <Box 
            position="static" 
            id="navSubHeader"
            flex={1}
            display="flex"
        >
            {children}
        </Box>
    )
}

export default VCNavigationSubheader