import React from "react"
import FooterBGImage from "../Assets/FooterBGImage.svg"
import HeaderBGImage from "../Assets/HeaderBGImage.svg"
import VCHomePageDetailSection from "./Component/VCHomePageDetailSection"
import "./Stylesheet/Home.css"
import AppImage from "../Assets/AppImage.png"
import VCHR from "./Component/VCHR"
import VCVideoReel from "./Component/VCVideoReel"
import VCResearcherBio from "./Component/VCResearcherBio"
import VCFeatureDetailIcon from "./Component/VCFeatureDetailIcon"
import VCFeatureDetailSection from "./Component/VCFeatureDetailSection"

const actionItems = [
    {
        name: "Learn More",
        action: () => alert("Learn More is a WIP"),
        color: "#7487a3",
    },
    {
        name: "Early Access",
        action: () => alert("Early Access is a WIP"),
        color: "#f9425f",
    }
]

const VCHomepage = (props) => {
    return (
        <div id="homePageBG">
            <div id="homePageContent">
                <VCHomePageDetailSection
                    headingText={"VocaCoord"}
                    details={
                        <>
                            <p>
                                Vocabulary learning is critical for oral and written language comprehension. 
                                However, for many students only hearing a new word a few times or seeing it 
                                written does not mean they have learned it.
                            </p>
                            <p>
                                Meet Vocabulary Coordinator &#40;VocaCoord&#41;. 
                                VocaCoord was developed to provide live, in&#45;the&#45;moment captioning of academic vocabulary 
                                for students with and without disabilities.
                            </p>
                            {/* <p>
                                VocaCoord utilizes Universal Design for Learning 
                                and Just&#45;in&#45;Time programming to capture and display key academic vocabulary during oral lessons. 
                                Users of VocaCoord are then able to review the vocabulary words during the lesson, 
                                as well as after the lesson during independent seat work.
                            </p>
                            <p>
                                VocaCoord provides each user with an image and a 
                                definition of the key words. Additionally, teachers are able to review individual student&apos;s use of VocaCoord, 
                                thus providing teachers the ability to tailor individualized vocabulary instruction and examine how students are learning the targeted vocabulary.
                            </p> */}
                        </>
                    }
                    actionItems={actionItems}
                    background={HeaderBGImage}
                    detailComponent={<img src={AppImage} className="detailComponent" alt="VocaCoord App UI" />                }
                />
                <VCHR />
                <VCHomePageDetailSection
                    headingText={"Meet VocaCoord's Research Team"}
                    details={<p>Meet the researchers that make VocaCoord possible.</p>}
                    alignCenter
                    centerBackground
                    headingType="h4"
                />
                <VCResearcherBio />
                <VCHR />
                <VCHomePageDetailSection
                    headingText={"Unique Features of Vocacoord"}
                    details={<></>}
                    alignCenter
                    centerBackground
                    headingType="h4"
                />
                <VCFeatureDetailSection />
                <VCHR />
                <VCHomePageDetailSection
                    headingText={"See VocaCoord in Action"}
                    details={<p>Watch videos about the VocaCoord application.</p>}
                    alignCenter
                    centerBackground
                    headingType="h4"
                />
                <div 
                    style={{
                        backgroundImage: `url(${FooterBGImage})`,
                    }}
                    className="videoReelBGImageWrapper"
                >
                    <VCVideoReel 
                        videoURL="https://youtu.be/JXQgZwGhdhY"
                        width="80vw"
                        style={{
                            margin: "0 auto"
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default VCHomepage