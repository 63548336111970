const MAX_FILE_SIZE = 10 * 1024 * 1024
const CLASSCODE_DOC_ID = "Ix9ZnlrahE5DpQBSYq0i"

export const uppyConfig = {
    id: "WordbankImageUploader",
    autoProceed: false,
    allowMultipleUploads: false,
    restrictions: {
        maxFileSize: MAX_FILE_SIZE,
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        allowedFileTypes: ["image/*"]
    }
}

/**
 * Create a new wordbank
 * 
 * @param {object} firestore The firestore object
 * @param {string} name The name of the wordbank
 * @param {string} description The wordbank description
 * @param {object} image The downloadURL and imagePath
 * @returns {*} false on data not set, else wordbank id
 */
export const createWordbank = async (firestore, name, description, image) => {

    if (!firestore) {
        throw new Error("The firestore object must be set to create a wordbank")
    }
    if (!name || !description) {
        return false
    }
    const classcodeData = await firestore.read(
        "Classcode",
        CLASSCODE_DOC_ID
    )

    const previousClassCode = classcodeData.lastUsedClassCode
    await firestore.update(
        "Classcode",
        CLASSCODE_DOC_ID,
        {
            lastUsedClassCode: previousClassCode + 1
        }
    )

    let imageData = {}
    if (image && image.downloadURL && image.imagePath) {
        imageData = {
            imageURL: image.downloadURL,
            fullImagePath: image.imagePath,
        }
    }
    const wordbankRef = await firestore?.create(
        "Wordbank",
        undefined,
        {
            name: name,
            description: description,
            wordIDs: [],
            inSession: false,
            wordBeingSpoken: null,
            studentIDs: [],
            classCode: previousClassCode + 1,
            ...imageData
        }
    )

    const userDoc = await firestore?.read(
        "User",
        firestore?.user.uid
    )

    const existingWordbankIDs = userDoc.wordbankIDs ?? {}

    await firestore?.update(
        "User",
        firestore?.user.uid,
        {
            wordbankIDs: [...existingWordbankIDs, wordbankRef.id]
        }
    )
    await firestore?.create(
        "Engagement",
        wordbankRef.id,
        {
            "studentActivity": {}
        }
    )
    return wordbankRef.id
}
/**
 * Edit a wordbank
 * 
 * @param {object} firestore The firestore object
 * @param {string} wordbankID The ID of the editing wordbank
 * @param {string} name The name of the editing wordbank
 * @param {string} description The description of the editing wordbank
 * @param {object} image The downloadURL and imagePath
 * @returns {*} false on data not set, else true
 */
export const editWordbank = async (firestore, wordbankID, name, description, image) => {
    if (!firestore) {
        throw new Error("The firestore object must be set to edit a wordbank")
    }
    if (!name || !description) {
        return false
    }

    let imageData = {}
    if (image && image.downloadURL && image.imagePath) {
        // The user has supplied a new image for the wordbank
        imageData = {
            imageURL: image.downloadURL,
            fullImagePath: image.imagePath,
        }
    }

    await firestore?.update(
        "Wordbank",
        wordbankID,
        {
            name: name,
            description: description,
            ...imageData
        }
    )

    return true
}

/**
 * Delete a wordbank and all associated data
 * 
 * @param {object} firestore The firestore object
 * @param {string} wordbankID The ID of the wordbank to be deleted
 * @returns {*} true on success
 */
export const deleteWordbank = async (firestore, wordbankID) => {
    // Get the user's wordbank array
    const userData = await firestore?.read(
        "User",
        firestore?.user.uid,
    )
    const wordbankIDs = userData.wordbankIDs
    // Get the (deleting) wordbank's word ids
    const wb = await firestore?.read(
        "Wordbank",
        wordbankID
    )
    const wordIDs = wb.wordIDs ?? []

    // Delete all words for the wordbank
    for (const i in wordIDs) {
        const wordID = wordIDs[i]
        const word = await firestore?.read(
            "Word",
            wordID
        )
        firestore?.deleteImage(word.fullImagePath)
        firestore?.deleteDocument(
            "Word",
            wordID
        )
    }
    // Remove the wordbank from the user's wb array
    let userWordbanks = []
    wordbankIDs.forEach(
        wbid => {
            if(wbid !== wordbankID)
                userWordbanks.push(wbid)
        }
    )
    await firestore?.update(
        "User",
        firestore?.user.uid,
        {
            ...userData,
            wordbankIDs: userWordbanks
        }
    )
    // Delete all engagements with the wordbank
    await firestore?.deleteDocument("Engagement", wordbankID)
    const studentIDs = wb.studentsIDs ?? []
    for (const i of studentIDs) {
        const studentID = studentIDs[i]
        await firestore?.deleteDocument("Student", studentID)
    }
    // Delete the wordbank
    firestore?.deleteImage(wb.fullImagePath)
    await firestore?.deleteDocument(
        "Wordbank",
        wordbankID,
    )
    return true
}
