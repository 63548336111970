import React, {
    useState,
    useRef,
} from "react"
import AppBar from "@mui/material/AppBar"
import VCLogo from "./VCLogo"
import Typography from "@mui/material/Typography"
import Toolbar from "@mui/material/Toolbar"
import Box from "@mui/material/Box"
import IconButton from "@mui/material/IconButton"
import VCLink from "./VCLink"
import {
    MdAccountCircle
} from "react-icons/md"
import VCNavBarPopover from "./VCNavbarPopover"

const VCNavbar = (props) => {
    const {
        firestore,
        links,
    } = props

    const [showPopover, setShowPopover] = useState(false)
    const accountButtonRef = useRef()

    const closeNavbarPopover = () => {
        setShowPopover(false)
    }

    return (
        <>
            {
                showPopover && (
                    <VCNavBarPopover 
                        show={showPopover} 
                        closeNavbarPopover={closeNavbarPopover}
                        anchorEl={accountButtonRef.current}
                    />
                )
            }
            <Box sx={{
                flexGrow: 1, 
                zIndex: 10000
            }}>
                <AppBar position="static" id="navbar">
                    <Toolbar>
                        <div id="navbarLogo">
                            <a href="/">
                                <VCLogo />
                            </a>
                        </div>
                        <Typography 
                            sx={{
                                flexGrow: 1, 
                            }} 
                            component="div" id="navBarSpacer" 
                        />
                        {
                            firestore.user ? (
                                <IconButton    
                                    ref={accountButtonRef}
                                    aria-haspopup="true"
                                    onClick={() => setShowPopover(!showPopover)} 
                                >
                                    <MdAccountCircle 
                                        id="accountSettings" 
                                    />
                                </IconButton>
                            ) : (
                                <>
                                    <div className="navLinkContainer">
                                        <VCLink href="/signup">Sign Up</VCLink>
                                    </div>
                                    <div className="navLinkContainer">
                                        <VCLink href="/login">Log In</VCLink>
                                    </div>
                                </>
                            )
                        }
                    </Toolbar>
                </AppBar>
            </Box>
        </>
    )
}

export default VCNavbar