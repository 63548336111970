import React from "react"
import Divider from '@mui/material/Divider'

const VCHR = (props) => {
    const {
        addGradient = true,
    } = props

    return (
        <div className="VCHR" />
    )
}

export default VCHR