import React from "react"
import {
    Link
} from "react-router-dom"
import Typography from "@mui/material/Typography"

const VCLink = (props) => {
    const {
        href, 
        children, 
        color="inherit",
        headingSize="h6",
        style,
        className="navLink",
        disable,
    } = props

    return (
        <Typography
            variant={headingSize}
        >
            {!disable ? (
                <Link 
                    to={href} 
                    style={{
                        color, 
                        ...style,
                    }}
                    className={className}
                >
                    {children}
                </Link>
            ) : children}
        </Typography>
    )
}

export default VCLink