import React, {
    useState
} from "react"

import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"

import VCImageUpload from "./VCImageUpload"
import {
    MdImage
} from "react-icons/md"

import {
    createWord,
    editWord,
    getWordbankID,
} from "../../ViewModel/WordsVM"

import NoImage from "./NoImage"

const WordModal = (props) => {
    const {
        firestore,
        uppy,
        onCreate,
        editingWord,
    } = props
    const [wordID, setWordID] = useState(editingWord?.id)
    const [imageURL, setImageURL] = useState(editingWord?.imageURL)
    const [imagePath, setImagePath] = useState(editingWord?.fullImagePath)
    const [showImageUpload, setShowImageUpload] = useState(false)
    const [wordName, setWordName] = useState(editingWord?.name)
    const [wordDefinition, setWordDefinition] = useState(editingWord?.description)
    const [wordbankID, setWordbankID] = useState(getWordbankID())

    const saveWord = async (editingWBObject) => {
        if (!wordDefinition || !wordName) {
            alert("Please set the name and definition to save a new word.")
            return false
        }
        if (editingWBObject) {
            await editWord(
                firestore, wordID, wordName, wordDefinition, {
                    downloadURL: imageURL,
                    imagePath,
                }
            )
            onCreate(wordID)

        } else {
            const wid = await createWord(
                firestore, wordbankID, wordName, wordDefinition, {
                    downloadURL: imageURL,
                    imagePath,
                }
            )
            if (!wid) {
                alert("There was an error saving the word.")
                return false
            }
            setWordID(wid)
            onCreate(wid)
            return true
        }
    }

    const deleteImage = async () => {
        if (!imagePath) {
            alert("There was an error deleting your image.")
            return false
        }
        await firestore?.deleteImage(imagePath)
        await firestore?.deleteDocumentField("Word", "imageURL", wordID)
        await firestore?.deleteDocumentField("Word", "fullImagePath", wordID)
        setImagePath(undefined)
        setImageURL(undefined)
        onCreate(wordID)
    }

    return (
        <>
            <Typography 
                textAlign="center"
                sx={{
                    fontSize: 20,
                    marginBottom: "10px"
                }}
            >
                {editingWord ? "Edit" : "Create"} Word
            </Typography>
            <Box
                justifyContent="center"
                alignItems="center"
                display="flex"
                sx={{
                    marginBottom: "10px"
                }}
            >
                <TextField
                    variant="standard" 
                    label="Word Name"
                    id="wordName"
                    className="wbFormField"
                    onChange={(e) => {
                        setWordName(e.target.value)
                    }}
                    style={{
                        marginRight: "20px"
                    }}
                    defaultValue={editingWord?.name ?? ""}
                    inputProps={{
                        maxLength: 30,
                    }}
                />
                <TextField
                    variant="standard" 
                    label="Word Definition"
                    id="wordDef"
                    className="wbFormField"
                    onChange={(e) => {
                        setWordDefinition(e.target.value)
                    }}
                    defaultValue={editingWord?.definition ?? ""}
                    inputProps={{
                        maxLength: 150,
                    }}
                />
            </Box>
            {imageURL && <img className="imagePreview" src={imageURL} alt="Word" />}
            {wordID && !imageURL && !showImageUpload && <NoImage />}
            {showImageUpload ? (
                <>
                    <VCImageUpload 
                        uppy={uppy} 
                        firestore={firestore} 
                        setImageURL={setImageURL}
                        setImagePath={setImagePath}
                        imageUploadSuccess={()=>setShowImageUpload(false)}
                    />
                    <Button 
                        onClick={()=>setShowImageUpload(false)}
                    >
                        Cancel
                    </Button>
                </>
            ) : (
                <div id="imageUploadControls">
                    {!imageURL ? (
                        <>
                            <div id="uploadContentSpace" />
                            <Button 
                                onClick={()=>setShowImageUpload(!showImageUpload)}
                            >
                                Upload Image
                            </Button>
                        </>
                    ) : (
                        <Button 
                            onClick={deleteImage}
                        >
                            Delete Image
                        </Button>
                    )}
                    <Button 
                        onClick={()=>saveWord(editingWord)}
                        disabled={!wordDefinition || !wordName}
                    >
                        {editingWord ? "Save" : "Create"} Word
                    </Button>
                </div>
            )}
        </>
    )
}

export default WordModal