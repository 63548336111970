import React from "react"
import VCLoadingIndicator from "./Component/VCLoadingIndicator"

const VCLoadingView = () => {
    return (
        <div className="loadingView">
            <VCLoadingIndicator size={30} />
        </div>
    )
}

export default VCLoadingView