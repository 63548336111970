import React, {
    useState
} from "react"

import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import {
    BsGoogle
} from "react-icons/bs"

import {
    Digital
} from "react-activity"

import "react-activity/dist/library.css"

import VCTile from "./Component/VCTile"

const VCLogin = ({ firestore }) => {

    const [email, setEmail] = useState(undefined)
    const [password, setPassword] = useState(undefined)
    const [loading, setLoading] = useState(false)
    const [loginErrorMessage, setLoginErrorMessage] = useState(undefined)

    const handleLoginAttempt = async (event) => {
        event.preventDefault()
        setLoading(true)
        const loginError = await firestore.signInWithEmail(email, password)
        if (loginError) {
            setLoginErrorMessage(loginError)
            setLoading(false)
        }
    }

    return (
        <VCTile>
            {loginErrorMessage && <p className="errorMessage">{loginErrorMessage}</p>}
            <form onSubmit={handleLoginAttempt} action="">
                <TextField 
                    variant="standard" 
                    label="Email"
                    id="email"
                    className="authFormField"
                    autoComplete="username"
                    type="email"
                    onChange={(e) => {
                        setEmail(e.target.value)
                    }}
                />
                <br />
                <br />
                <TextField 
                    variant="standard" 
                    label="Password"
                    id="password"
                    className="authFormField"
                    type="password"
                    autoComplete="current-password"
                    onChange={(e) => {
                        setPassword(e.target.value)
                    }}
                />
                <div className="authButtonContainer">
                    {loading ? <Digital /> : (
                        <Button
                            type="submit"
                            className="authFormField"
                        >
                            Login
                        </Button>
                    )}
                </div>
                <div className="authButtonContainer">
                    {loading ? <Digital /> : (
                        <Button
                            startIcon={
                                <BsGoogle />
                            }
                            onClick={firestore?.signInWithGoogle}
                            style={{
                                textTransform: "none",
                                boxShadow: "2px 2px 3px rgba(100,100,100,0.4)",
                                backgroundColor: "#10bbf1",
                                padding: "5px 15px",
                                color: "white",
                            }}
                        >
                            Sign In With Google
                        </Button>
                    )}
                </div>
            </form>
        </VCTile>
    )
}

export default VCLogin