import React from "react"

import {
    MdDelete,
    MdEdit,
} from "react-icons/md"

import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardActions from "@mui/material/CardActions"
import CardMedia from "@mui/material/CardMedia"
import Typography from "@mui/material/Typography"
import IconButton from "@mui/material/IconButton"
import Tooltip from "@mui/material/Tooltip"

import NoImage from "./NoImage"
import VCLink from "./VCLink"

const ActionItemTile = (props) => {
    const {
        actionItem,
        editAction,
        deleteAction,
        itemSelectionLink,
        style,
        cursor = "pointer",
    } = props
    return (
        <Card 
            elevation={3}
            style={{
                cursor,
                height: 320,
                width: 320,
                margin: "4px",
                ...style,
            }}
        >
            <VCLink 
                disable={!itemSelectionLink} 
                href={itemSelectionLink} 
                style={{ cursor: cursor }}
            >
                {actionItem.imageURL ? (
                    <CardMedia
                        component="img"
                        height={165}
                        alt="Wordbank"
                        image={actionItem.imageURL}
                    />
                ) : (
                    <NoImage />
                )}
                <CardContent>
                    <Typography 
                        sx={{
                            fontSize: 20
                        }}
                    >
                        {actionItem.name}
                    </Typography>
                    <Typography 
                        sx={{
                            fontSize: 15,
                            opacity: 0.6,
                            paddingBottom: 0,
                            
                        }}
                        className="wordbankDescription"
                    >
                        {actionItem.description ?? actionItem.definition ?? "No description given"}
                    </Typography>
                </CardContent>
            </VCLink>
            <CardActions className="actionsSection" disableSpacing>
                <Tooltip title="edit">
                    <IconButton onClick={()=>editAction(actionItem)}>
                        <MdEdit />
                    </IconButton>
                </Tooltip>
                <Tooltip title="delete">
                    <IconButton onClick={()=>deleteAction(actionItem)}>
                        <MdDelete />
                    </IconButton>
                </Tooltip>
            </CardActions>
        </Card>
    )
}

export default ActionItemTile