import React, {
    useState
} from "react"

import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import {
    render
} from "react-dom"
import {
    Digital
} from "react-activity"
import "react-activity/dist/library.css"
import VCTile from "./Component/VCTile"

const VCSignup = (props) => {
    
    const {
        firestore,
    } = props

    const [email, setEmail] = useState(undefined)
    const [password, setPassword] = useState(undefined)
    const [firstName, setFirstName] = useState(undefined)
    const [lastName, setLastName] = useState(undefined)

    const [loading, setLoading] = useState(false)
    const [signupErrorMessage, setSignupErrorMessage] = useState(undefined)

    const handleSignUpAttempt = async (event) => {
        event.preventDefault()
        setLoading(true)
        const signUpError = await firestore.signUpWithEmail(email, password, firstName, lastName)
        if (signUpError) {
            setSignupErrorMessage(signUpError)
            setLoading(false)
        }
    }

    return (
        <VCTile>
            {signupErrorMessage && <p className="errorMessage">{signupErrorMessage}</p>}
            <form onSubmit={handleSignUpAttempt} action="">
                <TextField 
                    variant="standard" 
                    label="First Name"
                    id="firstName"
                    className="authFormField"
                    onChange={(e) => {
                        setFirstName(e.target.value)
                    }}
                />
                <br />
                <br />
                <TextField 
                    variant="standard" 
                    label="Last Name"
                    id="lastName"
                    className="authFormField"
                    onChange={(e) => {
                        setLastName(e.target.value)
                    }}
                />
                <br />
                <br />
                <TextField 
                    variant="standard" 
                    label="Email"
                    id="email"
                    autoComplete="username"
                    className="authFormField"
                    type="email"
                    onChange={(e) => {
                        setEmail(e.target.value)
                    }}
                />
                <br />
                <br />
                <TextField 
                    variant="standard" 
                    label="Password"
                    id="password"
                    autoComplete="current-password"
                    className="authFormField"
                    type="password"
                    onChange={(e) => {
                        setPassword(e.target.value)
                    }}
                />
                <div className="authButtonContainer">
                    {loading ? <Digital /> : (
                        <Button
                            type="submit"
                            className="authFormField"
                        >
                            Sign Up
                        </Button>
                    )}
                </div>
            </form>
        </VCTile>
    )
}

export default VCSignup