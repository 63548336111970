import React, {
    useEffect,
    useState,
} from "react"

import {
    useUppy
} from "@uppy/react"

import Box from "@mui/material/Box"
import Dialog from "@mui/material/Dialog"
import Uppy from "@uppy/core"

// Custom components
import VCNavigationSubheader from "./Component/VCNavigationSubheader"
import WordbankModal from "./Component/WordbanksDialog"
import NewItemTile from "./Component/NewItemTile"
import ActionItemTile from "./Component/ActionItemTile"
import VCLoadingView from "./VCLoadingView"

// View Model
import {
    uppyConfig,
    deleteWordbank as deleteWordbankData
} from "../ViewModel/WordbanksVM"

import "@uppy/core/dist/style.css"
import "@uppy/dashboard/dist/style.css"
import "./Stylesheet/Wordbank.css"

let uppy = undefined

const Wordbanks = (props) => {

    const {
        firestore,
    } = props
    const [wordbanks, setWordbanks] = useState([])
    const [showWBModal, setShowWBModal] = useState(false)
    const [editingWordbank, setEditingWordbank] = useState(undefined)
    const [loading, setLoading] = useState(false)

    const loadWordbanks = async () => {
        setShowWBModal(false)
        setLoading(true)
        const userData = await firestore?.read(
            "User",
            firestore?.user.uid,
        )
        const wordbankIDs = userData.wordbankIDs

        let w = []
        for (const i in wordbankIDs) {
            const wid = wordbankIDs[i]
            const wordbankData = await firestore?.read(
                "Wordbank",
                wid,
            )
            
            w.push({ ...wordbankData, id: wid })
        }
        setWordbanks(w)
        setLoading(false)
    }

    const displayWordBankCreationUI = () => {
        setShowWBModal(true)

    }

    const closeWordBankCreationUI = () => {
        setShowWBModal(false)
    }

    const editWordbank = (wb) => {
        setEditingWordbank(wb)
        displayWordBankCreationUI()
    }

    const deleteWordbank = async (wb) => {
        await deleteWordbankData(firestore, wb.id)
        loadWordbanks()
    }

    useEffect(() => {
        loadWordbanks()
    }, [firestore])

    uppy = useUppy(() => {
        return new Uppy(uppyConfig)
    })
        
    return (
        <>
            <Dialog
                open={showWBModal}
                onClose={closeWordBankCreationUI}
            >
                <Box className="modal">
                    <WordbankModal 
                        firestore={firestore}
                        uppy={uppy}
                        onCreate={loadWordbanks}
                        editingWordbank={editingWordbank}
                    />
                </Box>
            </Dialog>
            {loading ? <VCLoadingView /> : (
                <>
                    <VCNavigationSubheader>Wordbanks</VCNavigationSubheader>
                    <Box
                        sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "center",
                            padding: "20px"
                        }}
                    >
                        <NewItemTile 
                            createNewItem={displayWordBankCreationUI}
                        />
                        {wordbanks.map(
                            wb => (
                                <ActionItemTile 
                                    deleteAction={deleteWordbank}
                                    editAction={editWordbank}
                                    actionItem={wb}
                                    itemSelectionLink={`/words?w=${wb.id}`}
                                    key={wb.id} 
                                />
                            )
                        )}
                    </Box>
                </>
            )}
        </>
    )
}

export default Wordbanks