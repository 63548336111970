import "./View/Stylesheet/Global.css"
import AppContainer from "./View/Component/AppContainer"
import {
    FirebaseContextProvider
} from "./Model/FirebaseContext"
import VCNavigator from "./View/Component/VCNavigator"
import VCNavbar from "./View/Component/VCNavbar"

/**
 *
 */
function App() {
    return (
        <FirebaseContextProvider>
            <AppContainer>
                <VCNavbar />
                <VCNavigator />
            </AppContainer>
        </FirebaseContextProvider>
    )
}

export default App
