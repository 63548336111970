import React from "react"
import Typography from "@mui/material/Typography"

const VCProgress = (props) => {

    const {
        progress,
        style,
        className = "imageUploadProgress",
    } = props

    return (
        <Typography 
            sx={{
                ...style,
            }}
            className={className}
        >
            {Math.round(progress)}% Complete
        </Typography>
    )
}

export default VCProgress