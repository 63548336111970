import React from "react"
import {
    Digital
} from "react-activity"
import "react-activity/dist/library.css"

const VCLoadingIndicator = (props) => {
    const {
        size
    } = props

    return (
        <Digital size={size} /> 
    )
}

export default VCLoadingIndicator