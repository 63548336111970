import React, {
    useState
} from "react"

import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"

import VCImageUpload from "./VCImageUpload"
import {
    MdImage
} from "react-icons/md"

import {
    createWordbank,
    editWordbank,
} from "../../ViewModel/WordbanksVM"

import NoImage from "./NoImage"

const WordbankModal = (props) => {
    const {
        firestore,
        uppy,
        onCreate,
        editingWordbank,
    } = props
    const [wordbankID, setWordbankID] = useState(editingWordbank?.id)
    const [imageURL, setImageURL] = useState(editingWordbank?.imageURL)
    const [imagePath, setImagePath] = useState(editingWordbank?.fullImagePath)
    const [showImageUpload, setShowImageUpload] = useState(false)
    const [wordbankName, setWordbankName] = useState(editingWordbank?.name)
    const [wordbankDescription, setWordbankDescription] = useState(editingWordbank?.description)

    const saveWordbank = async (editingWBObject) => {
        if (!wordbankDescription || !wordbankName) {
            alert("Please set the name and description to save a new wordbank.")
            return false
        }
        if (editingWBObject) {
            await editWordbank(
                firestore, wordbankID, wordbankName, wordbankDescription, {
                    downloadURL: imageURL,
                    imagePath,
                }
            )
            onCreate(wordbankID)

        } else {
            const wid = await createWordbank(
                firestore, wordbankName, wordbankDescription, {
                    downloadURL: imageURL,
                    imagePath,
                }
            )
            if (!wid) {
                alert("There was an error saving the wordbank.")
                return false
            }
            setWordbankID(wid)
            onCreate(wid)
            return true
        }
    }

    const deleteImage = async () => {
        if (!imagePath) {
            alert("There was an error deleting your image.")
            return false
        }
        await firestore?.deleteImage(imagePath)
        await firestore?.deleteDocumentField("Wordbank", "imageURL", wordbankID)
        await firestore?.deleteDocumentField("Wordbank", "fullImagePath", wordbankID)
        setImagePath(undefined)
        setImageURL(undefined)
        onCreate(wordbankID)
    }

    return (
        <>
            <Typography 
                textAlign="center"
                sx={{
                    fontSize: 20,
                    marginBottom: "10px"
                }}
            >
                {editingWordbank ? "Edit" : "Create"} Wordbank
            </Typography>
            <Box
                justifyContent="center"
                alignItems="center"
                display="flex"
                sx={{
                    marginBottom: "10px"
                }}
            >
                <TextField
                    variant="standard" 
                    label="Wordbank Name"
                    id="wordbankName"
                    className="wbFormField"
                    onChange={(e) => {
                        setWordbankName(e.target.value)
                    }}
                    style={{
                        marginRight: "20px"
                    }}
                    defaultValue={editingWordbank?.name ?? ""}
                    inputProps={{
                        maxLength: 30,
                    }}
                />
                <TextField
                    variant="standard" 
                    label="Wordbank Description"
                    id="wordbankDef"
                    className="wbFormField"
                    onChange={(e) => {
                        setWordbankDescription(e.target.value)
                    }}
                    defaultValue={editingWordbank?.description ?? ""}
                    inputProps={{
                        maxLength: 150,
                    }}
                />
            </Box>
            {imageURL && <img className="imagePreview" src={imageURL} alt="Wordbank" />}
            {wordbankID && !imageURL && !showImageUpload && <NoImage />}
            {showImageUpload ? (
                <>
                    <VCImageUpload 
                        uppy={uppy} 
                        firestore={firestore} 
                        setImageURL={setImageURL}
                        setImagePath={setImagePath}
                        imageUploadSuccess={()=>setShowImageUpload(false)}
                    />
                    <Button 
                        onClick={()=>setShowImageUpload(false)}
                    >
                        Cancel
                    </Button>
                </>
            ) : (
                <div id="imageUploadControls">
                    {!imageURL ? (
                        <>
                            <div id="uploadContentSpace" />
                            <Button 
                                onClick={()=>setShowImageUpload(!showImageUpload)}
                            >
                                Upload Image
                            </Button>
                        </>
                    ) : (
                        <Button 
                            onClick={deleteImage}
                        >
                            Delete Image
                        </Button>
                    )}
                    <Button 
                        onClick={()=>saveWordbank(editingWordbank)}
                        disabled={!wordbankDescription || !wordbankName}
                    >
                        {editingWordbank ? "Save" : "Create"} Wordbank
                    </Button>
                </div>
            )}
        </>
    )
}

export default WordbankModal