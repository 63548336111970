import React from "react"

import Typography from "@mui/material/Typography"

import {
    MdImage
} from "react-icons/md"

const NoImage = (props) => {
    return (
        <div className="noImageWrapper">
            <MdImage className="noImage" />
            <Typography className="noImageText">No Image</Typography>
        </div>
    )
}

export default NoImage