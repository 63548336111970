import React, {
    Children,
    isValidElement,
    cloneElement,
} from "react"
import FirebaseConsumer from "../../Model/FirebaseContext"

const AppContainer = (props) => {
    const {
        children
    } = props
    return (
        <FirebaseConsumer>
            {
                fctx => {
                    return (
                        <>
                            {
                                Children.map(children, child=> {
                                    if (isValidElement(child)) {
                                        return cloneElement(child, {
                                            firestore: fctx.firestore
                                        })
                                    }
                                })
                            }
                        </>
                    )
                }
            }
        </FirebaseConsumer>
    )
}

export default AppContainer