import React from "react"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import VCFeatureDetailIcon from "./VCFeatureDetailIcon"

const VCFeatureDetailSection = (props) => {
    return (
        <Grid
            container
            sx={{
                flex: "1",
                justifyContent: "space-around",
                padding: "30px"
            }}
        >
            <Box className="feature">
                <VCFeatureDetailIcon iconShape={1} />
                <h4 className="featureHeading">In the Moment Academic Vocabulary</h4>
                <p className="featureText">
                    Using UDL and JiT, VocaCoord displays on a tablet or computer screen, 
                    using blazing fast speech&#45;to&#45;text technology, 
                    academic vocabulary for students to reference as teachers are giving their lesson&#40;s&#41;.
                </p>
            </Box>
            <Box className="feature">
                <VCFeatureDetailIcon iconShape={2} />
                <h4 className="featureHeading">Vocabulary Learning</h4>
                <p className="featureText">
                    Pilot testing has indicated that VocaCoord assisted 4 children with hearing loss who were aided with cochlear 
                    implant learn new academic vocabulary in a one&#45;on&#45;one setting. 
                    VocaCoord is predicted to assist in all students learning and maintaining vocabulary knowledge.
                </p>
            </Box>
            <Box className="feature">
                <VCFeatureDetailIcon iconShape={3} />
                <h4 className="featureHeading">Compatibility</h4>
                <p className="featureText">
                    VocaCoord has been designed so it can work on both Android or Apple products &mdash; and is scaled for screens on phones, tablets, or computers.
                </p>
            </Box>
            <Box className="feature">
                <VCFeatureDetailIcon iconShape={4} />
                <h4 className="featureHeading">Large Scale</h4>
                <p className="featureText">Supports hundreds of active users, ensuring no student will ever be left out.</p>
            </Box>
        </Grid>
    )
}

export default VCFeatureDetailSection