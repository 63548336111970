import React, {
    useEffect,
    useState,
} from "react"

import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import TextField from "@mui/material/TextField"
import Select from "@mui/material/Select"
import IconButton from "@mui/material/IconButton"
import MenuItem from "@mui/material/MenuItem"
import InputLabel from "@mui/material/InputLabel"

import {
    MdDelete
} from "react-icons/md"

import VCLoadingIndicator from "./VCLoadingIndicator"
import {
    calculateStudentStats
} from "../../ViewModel/StudentsVM"

let studentMap = {}

const StudentsModal = (props) => {
    const {
        wordbank,
        firestore,
        wordMap,
        setWordbank,
    } = props

    const [students, setStudents] = useState([])
    const [engagements, setEngagements] = useState({})
    const [newStudentName, setNewStudentName] = useState("")
    const [newStudentEmail, setNewStudentEmail] = useState("")
    const [selectedStudentID, selectStudentID] = useState(undefined)
    const [loading, setLoading] = useState(false)

    const loadStudents = async () => {
        let s = []
        setLoading(true)
        for (const i in wordbank?.studentIDs) {
            const studentID = wordbank?.studentIDs[i]
            const student = await firestore?.read(
                "Student",
                studentID
            )
            s.push({
                ...student, 
                id: studentID,
            })
            studentMap[studentID] = student
        }
        setStudents(s)
        let e = await firestore?.read(
            "Engagement",
            wordbank.id
        )
        delete e.studentActivity
        if(!!Object.keys(e).length) {
            selectStudentID(Object.keys(e)[0])
        }
        setEngagements(e)
        setLoading(false)
    }

    const addStudent = async () => {
        if (!newStudentName) {
            alert("Please set the student name to add a new student")
            return false
        }
        const studentInfo = {
            name: newStudentName,
            email: newStudentEmail
        }
        const newStudentRef = await firestore?.create(
            "Student",
            undefined,
            studentInfo
        )
        const newStudentIDs = [...wordbank.studentIDs, newStudentRef.id]
        await firestore?.update(
            "Wordbank",
            wordbank.id,
            {
                studentIDs: newStudentIDs
            }
        )
        studentMap[newStudentRef.id] = studentInfo
        let newStudents = [...students]
        newStudents.push({ ...studentInfo, id: newStudentRef.id })
        setStudents(newStudents)
        let newWordbankData = { ...wordbank }
        newWordbankData.studentIDs = newStudentIDs
        setWordbank(newWordbankData)
    }

    const deleteStudent = async (studentID) => {
        await firestore?.deleteDocument(
            "Student",
            studentID
        )
        
        let newStudents = []
        let newStudentIDs = []
        students.forEach(
            student => {
                if (student.id !== studentID) {
                    newStudents.push(student)
                    newStudentIDs.push(student.id)
                }
            }
        )
        await firestore?.update(
            "Wordbank",
            wordbank.id,
            {
                studentIDs: newStudentIDs
            }
        )
        setStudents(newStudents)
        let newWordbankData = { ...wordbank }
        newWordbankData.studentIDs = newStudentIDs
        setWordbank(newWordbankData)
    }

    const handleStudentSelectionEvent = (e) => {
        selectStudentID(e.target.value)
    }

    useEffect(
        () => {
            loadStudents()
        }, [firestore, wordbank]
    )

    return (
        <Box
            id="studentsUI"
        >
            {!loading ? (
                <>
                    <Typography variant="h4" className="modalHeading">
                        Students in Class {String(wordbank.classCode).padStart(4, "0")}
                    </Typography>
                    {
                        <table id="studentNameTable">
                            <tbody>
                                {students.map(
                                    student => (
                                        <tr key={student.id}>
                                            <td>
                                                {student.name}
                                            </td>
                                            <td>
                                                {student.email}
                                            </td>
                                            <td>
                                                <IconButton onClick={()=>deleteStudent(student.id)}>
                                                    <MdDelete />
                                                </IconButton>
                                            </td>
                                        </tr>
                                    )
                                )}
                            </tbody>
                        </table>
                    }
                    {students.length === 0 && (
                        <Typography>
                            There are no students in this classroom yet.
                        </Typography>
                    )}
                    <Box id="studentEngagements">
                        
                        {selectedStudentID && engagements && Object.keys(engagements).length > 0 && (
                            <Box id="studentSelection">
                                <Typography variant="h4">
                                    Student-Word Engagements
                                </Typography>
                                <InputLabel id="student-select">Select a student</InputLabel>
                                <Select 
                                    onChange={handleStudentSelectionEvent} 
                                    value={selectedStudentID}
                                    labelId="student-select"
                                >
                                    {
                                        Object.keys(engagements).map(
                                            studentID => {
                                                return (
                                                    <MenuItem value={studentID} key={studentID}>
                                                        {studentMap[studentID]?.name ?? "<Deleted Student>"}
                                                    </MenuItem>
                                                )
                                            }
                                        )
                                    }
                                </Select>
                            </Box>
                        )}
                                
                        <table id="studentStatsTable">
                            <tbody>
                                {selectedStudentID && (
                                    <tr>
                                        <th>
                                            Word
                                        </th>
                                        <th>
                                            # of times read
                                        </th>
                                        <th>
                                            Avd. time (s)
                                        </th>
                                    </tr>
                                )}
                                {selectedStudentID && Object.keys(engagements[selectedStudentID]).length > 0 && (
                                    Object.keys(engagements[selectedStudentID]).map(
                                        wordID => {
                                            const wordData = wordMap[wordID]
                                            const {
                                                clicks,
                                                average
                                            } = calculateStudentStats(engagements[selectedStudentID][wordID])
                                            return (
                                                <tr key={wordID}>
                                                    <td>{wordData.name}</td>
                                                    <td>{clicks}</td>
                                                    <td>{average}</td>
                                                </tr>
                                            )
                                        }
                                    )
                                )}
                            </tbody>
                        </table>
                    </Box>
                    <Typography variant="h4" className="modalHeading">
                        Add a New Student
                    </Typography>
                    <Box
                        justifyContent="center"
                        alignItems="center"
                        display="flex"
                        sx={{
                            marginBottom: "10px"
                        }}
                    >
                        <TextField 
                            variant="standard" 
                            label="Student Name"
                            id="studentName"
                            onChange={(e) => {
                                setNewStudentName(e.target.value)
                            }}
                            style={{
                                marginRight: "15px"
                            }}
                        />
                        <TextField 
                            variant="standard" 
                            label="Student Email"
                            type="email"
                            id="studentEmail"
                            onChange={(e) => {
                                setNewStudentEmail(e.target.value)
                            }}
                        />
                    </Box>
                    <Box
                        alignItems="center"
                        justifyContent="center"
                        display="flex"
                    >
                        <Button 
                            variant="contained"
                            style={{
                                backgroundColor: "orange"
                            }}
                            onClick={addStudent}
                        >
                            Add Student
                        </Button>
                    </Box>
                </>
            ) : <VCLoadingIndicator />}
        </Box>
    )
}

export default StudentsModal