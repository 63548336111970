// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyA7JEJ6f__6cYI_0ktl37AeUCIt6ZSDSCo",
    authDomain: "vocacoord-b4626.firebaseapp.com",
    projectId: "vocacoord-b4626",
    storageBucket: "vocacoord-b4626.appspot.com",
    messagingSenderId: "840895225235",
    appId: "1:840895225235:web:c3216d8da8c43b420f9c66",
    measurementId: "G-2ZY828C128"
}

module.exports.firebaseConfig = firebaseConfig
