import React from "react"

import Typography from "@mui/material/Typography"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import VCAccessInformation from "./VCAccessInformation"

const VCHomePageDetailSection = (props) => {
    const {
        headingText,
        headingType = "h3",
        details,
        actionItems,
        background,
        detailComponent,
        alignCenter,
        fontWeight = "bold",
        centerBackground = false,
    } = props

    let textStylingCenterValue = {}
    if (alignCenter) {
        textStylingCenterValue.textAlign = "center"
    }

    return (
        <Grid 
            className="detailSection"
            container
            sx={{
                display: "flex",
                flex: 1,
            }}
            style={{
                backgroundImage: `url(${background})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: centerBackground ? "center" : "right bottom"
            }}
        >
            <Grid
                item
                xs={12} 
                md={detailComponent ? 6 : 12}
                className="detailSection"
            >
                <Grid 
                    container
                    justifyContent="center"
                    alignItems="center"
                    className="textDetailContainer"
                >
                    <div
                        className="textDetailWrapper"
                        style={{
                            maxWidth: detailComponent ? "initial" : "90vw"
                        }}
                    >
                        <Box 
                            sx={{
                                fontWeight, 
                                fontSize: `${headingType}.fontSize`,
                                color: "white",
                                ...textStylingCenterValue
                            }}
                            className="textDetailHeading"
                        >
                            {headingText}
                        </Box>
                        <Typography 
                            className="detailSubtext"
                            component="div"
                            sx={{
                                ...textStylingCenterValue,
                            }}
                        >
                            {details}
                        </Typography>
                    </div>
                    {actionItems && !!actionItems.length && (
                        <VCAccessInformation actionItems={actionItems} />
                    )}
                </Grid>
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
            >
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                >
                    {detailComponent && detailComponent}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default VCHomePageDetailSection