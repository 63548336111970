import React from "react"
import FeatureIcon1 from "../../Assets/FeatureIcon1.svg"
import FeatureIcon2 from "../../Assets/FeatureIcon2.svg"
import FeatureIcon3 from "../../Assets/FeatureIcon3.svg"
import FeatureIcon4 from "../../Assets/FeatureIcon4.svg"

const VCFeatureDetailIcon = (props) => {
    const {
        iconShape = 1
    } = props

    switch (iconShape) {
    case 1:
        return <img src={FeatureIcon1} alt="Feature Icon" />
    case 2:
        return <img src={FeatureIcon2} alt="Feature Icon" />
    case 3:
        return <img src={FeatureIcon3} alt="Feature Icon" />
    case 4:
        return <img src={FeatureIcon4} alt="Feature Icon" />
    default:
        return <img src={FeatureIcon1} alt="Feature Icon" />
    }
}

export default VCFeatureDetailIcon