import React, {
    useState,
    useEffect,
} from "react"

import SpeechRecognition, {
    useSpeechRecognition
} from "react-speech-recognition"

import Fab from "@mui/material/Fab"

import {
    MdMic as MicOnIcon,
    MdMicOff as MicOffIcon,
} from "react-icons/md"

const Dictaphone = (props) => {
    const {
        micIsOn,
        micClickHandler,
        setCurrentlySpokenWord,
        words,
    } = props

    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition()

    const handleSpokenWord = () => {        
        const spokenWord = words.find(
            word => {
                return transcript.toLowerCase().indexOf(word.name.toLowerCase()) > -1
            }
        )
        if (spokenWord) {
            setCurrentlySpokenWord(spokenWord)
            resetTranscript()
        }
    }

    useEffect(
        () => {
            handleSpokenWord(transcript)
        }, [transcript]
    )

    const toggleListening = () => {
        micClickHandler()
        if (!listening)
            SpeechRecognition.startListening({ continuous: true })
        else
            SpeechRecognition.stopListening()
    }

    if (!browserSupportsSpeechRecognition) {
        return <span>Browser doesn't support speech recognition.</span>
    }

    return (
        <Fab
            style={{
                position: "fixed",
                right: 10,
                bottom: 10,
                outline: "none",
                backgroundColor: "black"
            }}
            onClick={toggleListening}
        >
            {micIsOn ? (
                <MicOnIcon 
                    size={28} 
                    className="microphone" 
                />
            ) : (
                <MicOffIcon
                    size={28} 
                    className="microphone" 
                />
            )}
        </Fab>
    )
}

export default Dictaphone