import React from "react"
import VCLogin from "../VCLogin"
import VCSignup from "../VCSignup"
import Words from "../Words"
import Wordbanks from "../Wordbanks"
import VCHomepage from "../VCHomepage"
import VCAccountSettings from "../VCAccountSettings"
import Signout from "../Signout"
import VCLoadingView from "../VCLoadingView"

import {
    Route,
    Routes,
} from "react-router-dom"

const VCNavigator = ({ firestore }) => {
    const user = firestore.user
    return (
        typeof user === "undefined" ? <VCLoadingView /> : (
            <Routes>
                {!user ? (
                    <>
                        <Route path="/login" element={<VCLogin firestore={firestore} />} />
                        <Route path="/signup" element={<VCSignup firestore={firestore} />} />
                        <Route path="/" element={<VCHomepage />} />
                    </> 
                ) : ( 
                    <>
                        <Route path="/account" element={<VCAccountSettings firestore={firestore} />} />
                        <Route path="/signout" element={<Signout firestore={firestore} />} />
                        <Route path="/" element={<Wordbanks firestore={firestore} />} />
                        <Route path="/words" element={<Words firestore={firestore} />} />
                    </>

                )}
            </Routes>
        )
        
    )
}

export default VCNavigator