const MAX_FILE_SIZE = 10 * 1024 * 1024
const CLASSCODE_DOC_ID = "Ix9ZnlrahE5DpQBSYq0i"

export const uppyConfig = {
    id: "WordImageUploader",
    autoProceed: false,
    allowMultipleUploads: false,
    restrictions: {
        maxFileSize: MAX_FILE_SIZE,
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        allowedFileTypes: ["image/*"]
    }
}
/**
 * Create a new word
 * 
 * @param {object} firestore The firestore object
 * @param {string} wordbankID The ID of the wordbank to create the word in
 * @param {string} name The name of the word
 * @param {string} definition The definition of the word
 * @param {object} image An object like {downloadURL, imagePath} or undefined
 * @returns {*} false on data not set, else wordbank id
 */
export const createWord = async (firestore, wordbankID, name, definition, image) => {

    if (!firestore) {
        throw new Error("The firestore object must be set to create a word")
    }
    if (!name || !definition) {
        return false
    }

    let imageData = {}
    if (image && image.downloadURL && image.imagePath) {
        imageData = {
            imageURL: image.downloadURL,
            fullImagePath: image.imagePath,
        }
    }
    const wordRef = await firestore?.create(
        "Word",
        undefined,
        {
            name: name,
            definition: definition,
            ...imageData
        }
    )
    const wordbankData = await firestore.read(
        "Wordbank",
        wordbankID
    )

    const previousWordIDs = wordbankData.wordIDs ?? []
    await firestore?.update(
        "Wordbank",
        wordbankID,
        {
            wordIDs: [...previousWordIDs, wordRef.id]
        }
    )

    return wordRef.id
}

/**
 * Edit a word document in the firestore
 * 
 * @param {object} firestore firestore The firestore object
 * @param {string} wordID The editing word's ID
 * @param {string} name The editing word's name
 * @param {string} definition The editing word's definition
 * @param {object} image image An object like {downloadURL, imagePath} or undefined
 * @returns {*} false on data not set, else true
 */
export const editWord = async (firestore, wordID, name, definition, image) => {
    if (!firestore) {
        throw new Error("The firestore object must be set to edit a wordbank")
    }
    if (!name || !definition) {
        return false
    }
  
    let imageData = {}
    if (image && image.downloadURL && image.imagePath) {
        // The user has supplied a new image for the wordbank
        imageData = {
            imageURL: image.downloadURL,
            fullImagePath: image.imagePath,
        }
    }

    await firestore?.update(
        "Word",
        wordID,
        {
            name: name,
            definition: definition,
            ...imageData
        }
    )

    return true
}

/**
 * Delete a word and all associated data
 * 
 * @param {object} firestore The firestore object
 * @param {string} wordbankID The ID of the wordbank containing the word
 * @param {string} wordID The ID of the word to be deleted
 * @returns {*} true on success
 */
export const deleteWord = async (firestore, wordbankID, wordID) => {
    // Get the (deleting) wordbank's word ids
    const wb = await firestore?.read(
        "Wordbank",
        wordbankID
    )
    const wordIDs = wb.wordIDs ?? []

    const word = await firestore?.read(
        "Word",
        wordID
    )

    // Remove the word from the wordbanks wordIDs array
    let wordsInWordbank = []
    wordIDs.forEach(
        wid => {
            if(wid !== wordID)
                wordsInWordbank.push(wid)
        }
    )
    await firestore?.update(
        "Wordbank",
        wordbankID,
        {
            wordIDs: wordsInWordbank
        }
    )
    // Delete all engagements with the word
    const engagements = await firestore?.read(
        "Engagement",
        wordbankID
    )
    const previousEngagementData = engagements
    let newEngagementData = previousEngagementData
    if (previousEngagementData) {
        Object.keys(previousEngagementData).forEach(
            studentID => {
                delete newEngagementData[studentID][wordID]
            }
        )
    }
    firestore?.update(
        "Engagement",
        wordbankID,
        newEngagementData,
    )
    // Delete the word
    firestore?.deleteImage(word.fullImagePath)
    await firestore?.deleteDocument(
        "Word",
        wordID,
    )
    return true
}

/**
 * Gets the wordbank id from the url
 * 
 * @returns {string} the wordbank id from the url
 */
export const getWordbankID = () => {
    const query = window.location.search
    const params = new URLSearchParams(query)
    return params.get("w")
}