import React, {
    useEffect,
    useState,
} from "react"

import {
    Dashboard
} from "@uppy/react"

import Typography from "@mui/material/Typography"

import VCProgress from "./VCProgress"

const VCImageUpload = (props) => {
    const {
        uppy, 
        firestore,
        setImageURL,
        setImagePath,
        imageUploadSuccess,
    } = props

    const [progress, setProgress] = useState(0)

    const onUploadComplete = ({
        downloadURL, imagePath
    }) => {
        setImageURL(downloadURL)
        setImagePath(imagePath)
        imageUploadSuccess()
    }

    const uploadFile = async (fileID) => {
        const imageFile = uppy.getFile(fileID)
        firestore?.uploadImage(imageFile, onUploadComplete, setProgress)
    }
        
    useEffect(
        () => {
            uppy.addUploader(uploadFile)

            return function cleanup() {
                uppy.removeUploader(uploadFile)
            }
        }, [uppy]
    )
    return (
        <>
            {!!progress && progress < 100 && (
                <VCProgress 
                    progress={progress}
                />
            )}
            {!progress && (
                <div style={{
                    height: "44px"
                }}/>
            )}
            {
                progress >= 100 && (
                    <Typography className="uploadSuccessMessage">
                        Image uploaded successfully!
                    </Typography>
                )
            }
            <Dashboard
                proudlyDisplayPoweredByUppy={false} 
                uppy={uppy} 
                inline={true}
                showProgressDetails={true}
            />  
        </>          
    )
}

export default VCImageUpload
