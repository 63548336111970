/**
 * Calculate the engagement data for a given engagement object
 * 
 * @param {object} studentEngagementsForWord an array of {startTime, endTime} objects
 * @returns {object} the clicks for the current word and the 
 * average time the word was read for
 */
export const calculateStudentStats = (studentEngagementsForWord) => {
    let clicks = 0
    let average = 0.0
    studentEngagementsForWord.forEach(
        event => {
            average += ((event.endTime - event.startTime) / 1000)
            clicks += 1
        }
    )
    return {
        clicks,
        average: average.toFixed(2)
    }   
}
