import React from "react"
import Box from "@mui/material/Box"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Typography from "@mui/material/Typography"

import {
    MdAdd,
} from "react-icons/md"

const NewItemTile = (props) => {
    const {
        createNewItem,
        createItemHeading = "Create New Wordbank",
    } = props
    return (
        <Card 
            elevation={3}
            style = {{
                cursor: "pointer",
                height: 320,
                margin: "4px"
            }}
            onClick={createNewItem}
        >
            <CardContent>
                <Typography 
                    textAlign="center"
                    sx={{
                        fontSize: 20
                    }}
                    style={{
                        width: 288
                    }}
                >
                    {createItemHeading}
                </Typography>
                <Box
                    justifyContent="center"
                    alignItems="center"
                    display="flex"
                    sx={{
                        width: 288,
                        height: 200
                    }}
                >
                    <MdAdd id="add" />
                </Box>
            </CardContent>
        </Card>
    )
}

export default NewItemTile