import React, {
    useState,
    useEffect,
} from "react"
import VCTile from "./Component/VCTile"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import { Typography } from "@mui/material"

const VCAccountSettings = (props) => {

    const {
        firestore
    } = props

    const [email, setEmail] = useState("")
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [oldPassword, setOldPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")

    const loadUserData = async () => {
        const userData = await firestore?.read(
            "User",
            firestore?.user?.uid
        )
        setEmail(firestore?.user?.email)
        setFirstName(userData.firstName)
        setLastName(userData.lastName)
    }

    useEffect(
        () => {
            loadUserData()
        }, [firestore]
    )

    const handleSettingsChanges = (e) => {
        e.preventDefault()
        firestore?.updateUserAccountInfo(
            email, oldPassword, newPassword, firstName, lastName
        )
    }

    return (
        <VCTile style={{
            width: "250px"
        }}>
            <form onSubmit={handleSettingsChanges} action="">
                <Typography variant="h4">Account Settings</Typography>
                <TextField 
                    variant="standard" 
                    label="First Name"
                    id="firstName"
                    value={firstName}
                    className="authFormField"
                    onChange={(e) => {
                        setFirstName(e.target.value)
                    }}
                />
                <br />
                <br />
                <TextField 
                    variant="standard" 
                    label="Last Name"
                    id="lastName"
                    value={lastName}
                    className="authFormField"
                    onChange={(e) => {
                        setLastName(e.target.value)
                    }}
                />
                <br />
                <br />
                <TextField 
                    variant="standard" 
                    label="Email"
                    id="email"
                    value={email}
                    className="authFormField"
                    type="email"
                    autoComplete="username"
                    onChange={(e) => {
                        setEmail(e.target.value)
                    }}
                />
                <br />
                <br />
                <TextField 
                    variant="standard" 
                    label="Current Password"
                    id="password"
                    className="authFormField"
                    type="password"
                    autoComplete="current-password"
                    onChange={(e) => {
                        setOldPassword(e.target.value)
                    }}
                />
                <br />
                <br />
                <TextField 
                    variant="standard" 
                    label="New Password"
                    id="newPassword"
                    autoComplete="new-password"
                    className="authFormField"
                    type="password"
                    onChange={(e) => {
                        setNewPassword(e.target.value)
                    }}
                />
                <div className="authButtonContainer">
                    <Button
                        type="submit"
                        className="authFormField"
                    >
                        Update
                    </Button>
                </div>
            </form>
        </VCTile>
    )
}

export default VCAccountSettings