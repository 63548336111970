import React from "react"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import User from "../../Assets/User.png"
import Berry from "../../Assets/Berry.png"
import McCarthy from "../../Assets/McCarthy.jpeg"
import Berger from "../../Assets/Berger.png"
import Schwarz from "../../Assets/Schwarz.jpeg"

const VCResearcherBio = (props) => {
    return (
        <Grid
            container
            sx={{
                flex: "1",
                justifyContent: "space-around",
                padding: "30px"
            }}
        >
            <Box className="bio">
                <img alt="Michael Berry, PhD" className="bioImage" src={Berry} />
                <h4 className="bioHeading">Michael Berry, Ph.D.</h4>
                <p className="bioText">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            </Box>
            <Box className="bio">
                <img alt="Jillian McCarthy, PhD, CCC&#45;SLP" className="bioImage" src={McCarthy} />
                <h4 className="bioHeading">Jillian McCarthy, Ph.D., CCC&#45;SLP</h4>
                <p className="bioText">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            </Box>
            <Box className="bio">
                <img alt="Andrew Berger" className="bioImage" src={Berger} />
                <h4 className="bioHeading">Andrew Berger, B.S.</h4>
                <p className="bioText">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            </Box>
            <Box className="bio">
                <img alt="Ilsa Schwarz, PhD, CCC&#45;SLP" className="bioImage" src={Schwarz} />
                <h4 className="bioHeading">Ilsa Schwarz, Ph.D., CCC&#45;SLP</h4>
                <p className="bioText">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
            </Box>
        </Grid>
    )
}

export default VCResearcherBio