import React from "react"

const VCLogo = () => (
    <svg
        version="1.0"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 700.000000 417.000000"
        preserveAspectRatio="xMidYMid meet"
        height="50px"
        width="50px">
        <g transform="translate(-350,600) scale(0.200000,-0.200000)">
            <path
                fill="gray"
                d="M1827 3834 c-3 -4 -2 -15 4 -26 6 -11 321 -779 700 -1706 380 -928
          696 -1698 702 -1710 l12 -24 315 1 315 0 703 1718 c387 945 704 1725 705 1733
          2 13 -32 15 -247 15 l-249 0 -604 -1475 c-332 -811 -606 -1485 -609 -1497 -4
          -13 -9 -23 -14 -23 -4 0 -281 669 -615 1488 -334 818 -612 1493 -618 1500 -7
          9 -70 12 -252 12 -133 0 -245 -3 -248 -6z"
            />
            <path
                fill="orange"
                d="M3543 3823 c-3 -10 -119 -292 -256 -628 -137 -335 -252 -619 -254
          -630 -2 -11 38 -123 90 -250 52 -126 97 -238 100 -247 7 -19 27 -25 27 -9 0 5
          160 400 356 878 196 477 356 875 357 883 2 13 -28 15 -206 18 -191 2 -208 1
          -214 -15z"
            />
            <path
                fill="orange"
                d="M4160 3818 c-98 -232 -820 -2012 -820 -2021 0 -22 196 -491 207 -494
          5 -2 18 18 28 44 10 26 241 590 512 1253 272 663 495 1212 496 1220 2 13 -28
          15 -206 18 -207 2 -207 2 -217 -20z"
            />
        </g>
    </svg>
)

export default VCLogo