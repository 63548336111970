import React from "react"

const VCTile = (props) => {
    const {
        children,
        style,
    } = props

    return (
        <div className="tileContainer">
            <div className="tile" style={{ ...style }}>
                {children}
            </div>
        </div>
    )
}

export default VCTile